<template>
  <div class="input-range">
    <year-month-input
      idx="0"
      ref="beg"
      @end="
        $refs.beg.blur();
        $refs.end.focus();
      "
    ></year-month-input>
    <div>---</div>
    <year-month-input idx="1" ref="end"></year-month-input>
  </div>
</template>

<script>
import YearMonthInput from "./YearMonthInput.vue";
export default {
  components: {
    YearMonthInput,
  },
  methods: {
    getValue() {
      const { beg, end } = this.$refs
      return [beg.getValue(), end.getValue()]
    }
  }
};
</script>

<style scoped>
.input-range {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>