<template>
  <div
    class="input"
    :tabindex="idx"
    outline="0"
    hidefocus="true"
    ref="input"
    @blur="blur"
  >
    <van-password-input
      v-bind:class="[
        'p-input',
        'year-input',
        showKeyboardYear ? 'act-input' : '',
      ]"
      :value="year"
      length="4"
      :mask="false"
      :focused="showKeyboardYear"
      @focus="onInputFocus('year')"
    />
    <div class="text">年</div>
    <van-password-input
      v-bind:class="[
        'p-input',
        'month-input',
        showKeyboardMonth ? 'act-input' : '',
      ]"
      :value="month"
      length="2"
      :mask="false"
      :focused="showKeyboardMonth"
      @focus="onInputFocus('month')"
    />
    <div class="text">月</div>
    <van-number-keyboard
      :show="showKeyboardYear"
      @input="onKbYearInput"
      @delete="onKbYearDelete"
      @blur="showKeyboardYear = false"
    />
    <van-number-keyboard
      @input="onKbMonthInput"
      @delete="onKbMonthDelete"
      :show="showKeyboardMonth"
      @blur="showKeyboardMonth = false"
    />
  </div>
</template>

<script>
import { PasswordInput, NumberKeyboard } from "vant";
export default {
  props: ["idx"],
  components: {
    [PasswordInput.name]: PasswordInput,
    [NumberKeyboard.name]: NumberKeyboard,
  },
  data() {
    return {
      year: "",
      month: "",
      showKeyboardYear: false,
      showKeyboardMonth: false,
    };
  },
  watch: {
    year() {
      if (this.year.length >= 4) {
        this.showKeyboardYear = false;
        this.showKeyboardMonth = true;
      }
    },
    month() {
      if (this.month.length === 2) {
        this.$emit("end", this.getValue());
      }
    },
    showKeyboardMonth() {
      if (this.showKeyboardMonth) {
        return;
      }
      const length = this.month.length;
      if (length === 1 && this.month === "1") {
        this.month = "0" + this.month;
      }
    },
  },
  methods: {
    onKbYearInput(value) {
      const length = this.year.length;
      if (length >= 4) {
        return;
      }
      this.year += value;
      console.log("onKbYearInput", this.year);
    },
    onKbYearDelete() {
      const length = this.year.length;
      if (length <= 0) {
        return;
      }

      this.year = this.year.substr(0, length - 1);
    },
    onKbMonthInput(value) {
      const length = this.month.length;
      if (length >= 2) {
        return;
      }
      console.log(value);
      if (length === 0 && value !== 0 && value !== 1) {
        value = "0" + value;
      }

      this.month += value;
      console.log("onKbMonthInput", this.month);
    },
    onKbMonthDelete() {
      const length = this.month.length;
      if (length <= 0) {
        return;
      }

      this.month = this.month.substr(0, length - 1);
    },
    onInputFocus(type) {
      console.log("focus", type);
      if (type === "year") {
        this.showKeyboardYear = true;
        this.showKeyboardMonth = false;
      } else {
        this.showKeyboardYear = false;
        this.showKeyboardMonth = true;
      }
    },
    onMonthBlur() {
      console.log("month blur");
    },
    focus() {
      this.$refs.input.focus();
      this.onInputFocus("year");
    },
    blur() {
      console.log("YearMonthInput blur");
      this.showKeyboardYear = false;
      this.showKeyboardMonth = false;
    },
    getValue() {
      return this.year + "/" + this.month;
    },
    clear() {
      this.year = ''
      this.month = ''
    }
  },
};
</script>

<style scoped>
.input {
  width: 140px;
  display: flex;
  align-items: center;
  padding: 12px 0px;
  font-size: 14px;
  color: #333;
}

.input .text {
  position: relative;
  bottom: -8px;
}

.input >>> .van-password-input {
  margin: 0 4px;
}

.input-div {
  height: 38px;
  line-height: 38px;
  color: #000;
  font-size: 24px;
  white-space: nowrap;
}

.act-input {
  border: 1px solid red;
}

.p-input {
  height: 30px;
}

.year-input {
  width: 65px;
}

.month-input {
  width: 35px;
}

.p-input >>> .van-password-input__item {
  font-weight: bold;
  color: #000;
  font-size: 24px;
  background-color: #ebedf0;
}
</style>