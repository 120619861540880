<template>
  <div>
    <van-form @submit="onSubmit">
      <component
        :is="componentName"
        init-data="Q123"
        name="xxx.a"
        label="测试"
        :rules="[{ required: true, message: '请填写用户名' }]"
      ></component>
      <component
        :is="componentName"
        init-data=""
        name="xxx.b"
        label="测试picker"
        :rules="[{ required: true, message: '请选择' }]"
      ></component>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <div class="input-box">
      <div>间断时间</div>
      <year-month-range-input ref="rangeDate"></year-month-range-input>
    </div>
    <van-button round block type="button" @click="getDate">获取时间</van-button>
  </div>
</template>

<script>
import { Form, Button } from "vant";
import TestOne from "./TestOne";
import TestTwo from "./TestTwo";
import YearMonthRangeInput from './YearMonthRangeInput';

export default {
  components: {
    [Form.name]: Form,
    [Button.name]: Button,
    YearMonthRangeInput,
    TestOne,
    TestTwo,
  },
  data() {
    return {
      componentName: 'TestOne'
    }
  },
 
  methods: {
    onSubmit(values) {
      console.log(values);
    },
    getDate() {
      this.$showInfo(this.$refs.rangeDate.getValue())
    }
  },
};
</script>

<style scoped>
.input-box {
  padding: 12px 4px;
  margin: 0 18px;
  font-size: 16px;
  color: #333333;
  background-color: #fff;
}

</style>