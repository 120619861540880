<template>
<div>
  <van-field v-bind="$attrs" :value="v" @input="onIput"/>
  <div v-if="errMsg">{{errMsg}}</div>
  </div>
</template>

<script>
import { Field } from 'vant';

export default {
  props: ['initData', 'errMsg'],
  components: {
    [Field.name]: Field
  },
  data() {
    return {
      v: this.initData
    }
  },
  methods: {
    onIput(value) {
      this.v = value
    }
  }
}
</script>

<style>

</style>