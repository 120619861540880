<template>
  <div>
    <van-field
      v-bind="$attrs"
      :value="v.value"
      readonly
      clickable
      @click="showPicker = true"
    >
      <template #input>
        {{ v.label }}
      </template>
      <template #extra>
        <van-popup v-model="showPicker" position="bottom" get-container="body">
          <van-picker
            show-toolbar
            value-key="label"
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
      </template>
    </van-field>
    <div v-if="errMsg" class="field-err">{{errMsg}}</div>
  </div>
</template>

<script>
import { Field, Popup, Picker } from "vant";
const columns = [
  { label: "杭州", value: "1" },
  { label: "宁波", value: "2" },
];

export default {
  props: ['initData', 'errMsg'],
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  data() {
    return {
      v: this.convert(this.initData),
      showPicker: false,
      columns,
    };
  },
  methods: {
    convert(value) {
      const res = columns.find(item => item.value === value)
      return res !== undefined ? res : {}
    },
    onConfirm(value) {
      this.v = value;
      this.showPicker = false;
    },
  },
};
</script>

<style>
</style>